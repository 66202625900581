<!-- 人群分析  By阿旭 -->
<template>
  <div class="main">

    <div class="top_btn">

      <div class="left-item">
        <el-input @keyup.enter.native="query()" style="width:200px;margin-right: 10px;" v-model="ruleName" placeholder="管控方案"></el-input>
        <el-select style="width:200px;margin-right: 10px;" v-model="code" placeholder="人群分类" filterable clearable>
          <el-option v-for="item in maincategoryList" :key="item.dictValue" :label="item.dictLabel"
                     :value="item.dictValue"></el-option>
        </el-select>
        <el-select :clearable="true" v-model="status" placeholder="状态" @keyup.enter.native="query()">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>

      <div class="right-item">
        <el-button type="primary" size="small" @click="query()">查询</el-button>
        <el-button type="primary" size="small" @click="newaddCrowdInfo">新增人群</el-button>
      </div>
      
    </div>


    <TableModel :is-show-select="true" :loading="loading" :table-data="tableData" table-size="medium">
      <el-table-column prop="hc" label="操作" width="200">
        <template slot-scope="scope">
          <el-link type="primary" style="margin-right:10px;" @click="editCrowdInfo(scope.row)">编辑</el-link>
          <el-link v-if="scope.row.status == 1" type="primary" style="color:#ff5050" @click="dictionaryFun(scope.row)">
            停用</el-link>
          <el-link v-if="scope.row.status == 0" type="primary" style="color:#12e07c" @click="dictionaryFun(scope.row)">
            启用</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="ruleName" label="管控方案" width="400" />
      <el-table-column prop="name" label="人群分类" width="400" />
      <el-table-column prop="remark" label="说明" width="400" />
      <el-table-column prop="status" label="状态" width="250">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.status == 1">启动</el-tag>
          <el-tag size="small" type="danger" v-else>停用</el-tag>
        </template>
      </el-table-column>
    </TableModel>

    <div class="content_page">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNumber"
        :page-size="pageSize" :total="total" :page-sizes="[10, 20, 40, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    </div>

    <!-- 编辑规则 -->
    <el-dialog title="规则编辑" :visible.sync="ruleEditing" width="60%" :before-close="handleClose" :close-on-click-modal="false">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="管控方案" prop="ruleName">
          <el-input v-model="ruleForm.ruleName" class="mingCheng"></el-input>
        </el-form-item>
        <div style="display:flex">
          <el-form-item label="人群分类" prop="name">
            <el-select v-model="ruleForm.name" placeholder="请选择" clearable :filterable="true">

              <el-option v-for="item in maincategoryList" :key="item.dictValue" :label="item.dictLabel"
                :value="item.dictValue" @click.native="handelCrowdType(item)"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="入住表单" prop="checkForm" v-show="myshow">
            <el-select v-model="ruleForm.checkForm" placeholder="请选择">
              <el-option v-for="item in checkFormList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item label="说明" prop="remark">
          <el-input v-model="ruleForm.remark" placeholder="管控措施说明"></el-input>
        </el-form-item>
      </el-form>
      <div>
        <div class="ruleEditing_gk">
          <strong style="margin-right:20px;font-size: 18px;">管控措施</strong>
          <el-dropdown @command="handleCommand">
            <el-button type="primary">
              添加步骤<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="a">居家隔离</el-dropdown-item>
              <el-dropdown-item command="b">集中隔离</el-dropdown-item>
              <el-dropdown-item command="c">健康检测</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <el-tabs v-model="activeName" @tab-click="handleClick" closable @tab-remove="removeTab">
          <el-tab-pane v-if="isa" label="居家隔离" name="a">
            <div class="gk_top">
              <div class="isexecute">
                <p>是否必须执行</p>
                <el-checkbox style="margin:0px" v-model="checkeda01"></el-checkbox>
                <el-checkbox style="margin-top:43px" v-model="checkeda02"></el-checkbox>
              </div>
              <el-form :model="gkForm" ref="gkForm" :rules="rules" label-width="180px" class="demo_gkForm">
                <el-form-item label="开始隔离告知书类型：" prop="startBookCode">
                  <el-select v-model="gkForm.startBookCode" placeholder="请选择">
                    <el-option v-for="item in ksoptions" :key="item.noticeBookCode" :label="item.noticeBookName"
                               :value="item.noticeBookCode" @click.native="gkFormStartBookCode(item)">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="解除隔离告知书类型：" prop="relieveBookCode">
                  <el-select v-model="gkForm.relieveBookCode" placeholder="请选择">
                    <el-option v-for="item in jcoptions" :key="item.noticeBookCode" :label="item.noticeBookName"
                               :value="item.noticeBookCode" @click.native="gkFormRelieveBookCode(item)">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="顺序：" prop="seq">
                  <el-input-number v-model="gkForm.seq" controls-position="right" @change="handleChange" :min="0"
                    :max="3">
                  </el-input-number>
                </el-form-item>
                <el-form-item label="隔离天数：" prop="days">
                  <el-input-number v-model="gkForm.days" controls-position="right" @change="handleChange" :min="0"
                    :max="100">
                  </el-input-number>
                </el-form-item>
                <el-form-item label="每次体温测量次数：" prop="tempTimes">
                  <el-input-number v-model="gkForm.tempTimes" controls-position="right" @change="handleChange" :min="0"
                    :max="100">
                  </el-input-number>
                </el-form-item>
              </el-form>
            </div>
            <div class="detect">
              <div class="detect_hs">
                核酸检测
                <div class="row-item">
                  <el-form>
                    <el-form-item v-for="(item, index) in gkForm.labDays" :label="'核酸检测节点 ' + (index + 1) + '：'"
                      :key="item.key" :prop="'labDays.' + index + '.labDaysName'">
                      第

                      <el-input style="width: 118px;" type="number" size="medium" v-model="item.labDaysName" @input="inputChange()"
                        placeholder="请选择" />
                      天
                      <el-button style="margin-left:10px" size="medium" @click.prevent="removeDomain(index, '核酸')">删除
                      </el-button>
                    </el-form-item>
                  </el-form>
                </div>
                <el-button size="small" @click="addDomain('核酸')">添加核酸检测点</el-button>
              </div>
              <div class="detect_kt">
                抗体检测
                <div class="row-item">
                  <el-form>
                    <div class="row-item">
                      <el-form-item v-for="(item, index) in gkForm.antibody" :label="'抗体检测节点 ' + (index + 1) + '：'"
                        :key="item.key" :prop="'antibody.' + index + '.value'">
                        第
                        <el-input style="width: 118px;" type="number" size="medium" v-model="item.antibodyvalue" @input="inputChange()"
                          placeholder="请选择" /> 天
                        <el-button style="margin-left:10px" size="medium" @click.prevent="removeDomain(index, '抗体')">删除
                        </el-button>
                      </el-form-item>
                    </div>
                  </el-form>
                </div>
                <el-button size="small" @click="addDomain('抗体')">添加抗体检测点</el-button>

              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane v-if="isb" label="集中隔离" name="b">
            <div class="gk_top">
              <div class="isexecute">
                <p>是否必须执行</p>
                <el-checkbox style="margin:0px" v-model="checkedb01"></el-checkbox>
                <el-checkbox style="margin-top:43px" v-model="checkedb02"></el-checkbox>
              </div>
              <el-form :model="gkFormb" ref="gkFormb" :rules="rules" label-width="180px" class="demo_gkForm">
                <el-form-item label="开始隔离告知书类型：" prop="startBookCode">
                  <el-select v-model="gkFormb.startBookCode" placeholder="请选择">
                    <el-option v-for="item in ksoptions" :key="item.noticeBookCode" :label="item.noticeBookName"
                               :value="item.noticeBookCode" @click.native="gkFormbStartBookCode(item)">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="解除隔离告知书类型：" prop="relieveBookCode">
                  <el-select v-model="gkFormb.relieveBookCode" placeholder="请选择">
                    <el-option v-for="item in jcoptions" :key="item.noticeBookCode" :label="item.noticeBookName"
                               :value="item.noticeBookCode" @click.native="gkFormbRelieveBookCode(item)">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="顺序：" prop="seq">
                  <el-input-number v-model="gkFormb.seq" controls-position="right" @change="handleChange" :min="0"
                    :max="3">
                  </el-input-number>
                </el-form-item>
                <el-form-item label="隔离天数：" prop="days">
                  <el-input-number v-model="gkFormb.days" controls-position="right" @change="handleChange" :min="0"
                    :max="100">
                  </el-input-number>
                </el-form-item>
                <el-form-item label="每次体温测量次数：" prop="tempTimes">
                  <el-input-number v-model="gkFormb.tempTimes" controls-position="right" @change="handleChange" :min="0"
                    :max="100">
                  </el-input-number>
                </el-form-item>
              </el-form>
            </div>
            <div class="detect">
              <div class="detect_hs">
                核酸检测
                <div class="row-item">
                  <el-form>
                    <el-form-item v-for="(item, index) in gkFormb.labDays" :label="'核酸检测节点 ' + (index + 1) + '：'"
                      :key="item.key" :prop="'labDays.' + index + '.labDaysName'">
                      第
                      <el-input style="width: 118px;" type="number" size="medium" v-model="item.labDaysName" @input="inputChange()"
                        placeholder="请选择" />
                      天
                      <el-button style="margin-left:10px" size="medium" @click.prevent="removeDomainb(index, '核酸')">删除
                      </el-button>
                    </el-form-item>
                  </el-form>
                </div>
                <el-button size="small" @click="addDomainb('核酸')">添加核酸检测点</el-button>
              </div>
              <div class="detect_kt">
                抗体检测
                <div class="row-item">
                  <el-form>
                    <div class="row-item">
                      <el-form-item v-for="(item, index) in gkFormb.antibody" :label="'抗体检测节点 ' + (index + 1) + '：'"
                        :key="item.key" :prop="'gkFormb.antibody.' + item + '.value'">
                        第
                        <el-input style="width: 118px;" type="number" size="medium" v-model="item.antibodyvalue" @input="inputChange()"
                          placeholder="请选择" /> 天
                        <el-button style="margin-left:10px" size="medium" @click.prevent="removeDomainb(index, '抗体')">删除
                        </el-button>
                      </el-form-item>
                    </div>
                  </el-form>
                </div>
                <el-button size="small" @click="addDomainb('抗体')">添加抗体检测点</el-button>

              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane v-if="isc" label="健康检测" name="c">
            <div class="gk_top">
              <div class="isexecute">
                <p>是否必须执行</p>
                <el-checkbox style="margin:0px" v-model="checkedc01"></el-checkbox>
                <el-checkbox style="margin-top:43px" v-model="checkedc02"></el-checkbox>
              </div>
              <el-form :model="gkFormc" ref="gkFormc" :rules="rules" label-width="180px" class="demo_gkForm">
                <el-form-item label="开始隔离告知书类型：" prop="startBookCode">
                  <el-select v-model="gkFormc.startBookCode" placeholder="请选择">
                    <el-option v-for="item in ksoptions" :key="item.noticeBookCode" :label="item.noticeBookName"
                               :value="item.noticeBookCode"  @click.native="gkFormcStartBookCode(item)">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="解除隔离告知书类型：" prop="relieveBookCode">
                  <el-select v-model="gkFormc.relieveBookCode" placeholder="请选择">
                    <el-option v-for="item in jcoptions" :key="item.noticeBookCode" :label="item.noticeBookName"
                               :value="item.noticeBookCode"  @click.native="gkFormcRelieveBookCode(item)">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="顺序：" prop="seq">
                  <el-input-number v-model="gkFormc.seq" controls-position="right" @change="handleChange" :min="0"
                    :max="3">
                  </el-input-number>
                </el-form-item>
                <el-form-item label="隔离天数：" prop="days">
                  <el-input-number v-model="gkFormc.days" controls-position="right" @change="handleChange" :min="0"
                    :max="100">
                  </el-input-number>
                </el-form-item>
                <el-form-item label="每次体温测量次数：" prop="tempTimes">
                  <el-input-number v-model="gkFormc.tempTimes" controls-position="right" @change="handleChange" :min="0"
                    :max="100">
                  </el-input-number>
                </el-form-item>
              </el-form>
            </div>
            <div class="detect">
              <div class="detect_hs">
                核酸检测
                <div class="row-item">
                  <el-form>
                    <el-form-item v-for="(item, index) in gkFormc.labDays" :label="'核酸检测节点 ' + (index + 1) + '：'"
                      :key="item.key" :prop="'labDays.' + index + '.labDaysName'">
                      第
                        <el-input style="width: 118px;" type="number" size="medium" v-model="item.labDaysName" @input="inputChange()"
                        placeholder="请选择" />
                      天
                      <el-button style="margin-left:10px" size="medium" @click.prevent="removeDomainc(index, '核酸')">删除
                      </el-button>
                    </el-form-item>
                  </el-form>
                </div>
                <el-button size="small" @click="addDomainc('核酸')">添加核酸检测点</el-button>
              </div>
              <div class="detect_kt">
                抗体检测
                <div class="row-item">
                  <el-form>
                    <div class="row-item">
                      <el-form-item v-for="(item, index) in gkFormc.antibody" :label="'抗体检测节点 ' + (index + 1) + '：'"
                        :key="item.key" :prop="'antibody.' + index + '.value'">
                        第
                        <el-input style="width: 118px;" type="number" size="medium" v-model="item.antibodyvalue" @input="inputChange()"
                          placeholder="请选择" /> 天
                        <el-button style="margin-left:10px" size="medium" @click.prevent="removeDomainc(index, '抗体')">删除
                        </el-button>
                      </el-form-item>
                    </div>
                  </el-form>
                </div>
                <el-button size="small" @click="addDomainc('抗体')">添加抗体检测点</el-button>

              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ruleEditing = false">取 消</el-button>
        <el-button type="primary" @click="surepp">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {rqtype,noticeBookType, rulecrowdtist, rulecrowdupdateStatus} from "../../api/RuleManagement/crowdAnalysis";
import TableModel from '../../components/TableModel';
import { rulecrowdsave, ruleCrowdInfo, rulecrowdupdate } from "@/api/RuleManagement/crowdAnalysis";
import { mapState } from "vuex";

export default {
  components: { TableModel },
  data() {
    return {
      ruleName: "", //绑定的人群分类名称
      status: '',
      code:"",
      isArrange: false,
      loading: false,
      isa: false,
      isb: false,
      isc: false,
      checkeda01: "",
      checkeda02: "",
      checked: false,
      checkedb01: false,
      checkedb02: false,
      checkedc01: false,
      checkedc02: false,
      tableData: [],
      ruleForm: {},
      gkForm: {
        num: 0,
        startBookCode: "",
        startBookName: "",
        relieveBookCode: "",
        relieveBookName: "",
        labDays: [{ labDaysName: "" }],
        antibody: [{ antibodyvalue: "" }],
        controlType: 2
      },
      gkFormb: {
        num: 0,
        startBookCode: "",
        startBookName: "",
        relieveBookCode: "",
        relieveBookName: "",
        labDays: [{ labDaysName: "" }],
        antibody: [{ antibodyvalue: "" }],
        controlType: 3
      },
      gkFormc: {
        num: 0,
        startBookCode: "",
        startBookName: "",
        relieveBookCode: "",
        relieveBookName: "",
        labDays: [{ labDaysName: "" }],
        antibody: [{ antibodyvalue: "" }],
        controlType: 1
      },
      pageNumber: 1,
      pageSize: 20,
      total: 0,
      options: [{
        value: '1',
        label: '启动'
      }, {
        value: '0',
        label: '停用'
      }],
      activeName: "",
      ruleEditing: false,
      iseddd: false, //是否修改
      maincategoryList: [], //大分类
      ksoptions: [],
      jcoptions: [],
      checkFormList: [{
        value: '1',
        label: '表单1'
      }, {
        value: '2',
        label: '表单2'
      }, {
        value: '3',
        label: '表单3'
      }, {
        value: '4',
        label: '表单4'
      }], //入住表单
      rules: {
        ruleName: [
          { required: true, message: '请输入管控方案', trigger: 'blur' },
          { min: 0, max: 50, message: '长度在 0 到 50 个字符', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请选择人群分类', trigger: 'blur' },
        ],
        remark: [
          { required: true, message: '请填写管控说明', trigger: 'blur' },
        ],
        days: [
          { required: true, message: '请输入隔离天数', trigger: 'blur' },
        ],
        tempTimes: [
          { required: true, message: '请输入每次体温测量次数', trigger: 'blur' },
        ],
      },
      myshow:false
    };
  },

  created() {
    this.getListInfo();
    this.getCrowdTypeList();
    this.getNoticeBookTypeKs();
    this.getNoticeBookTypeJc();
  },
  mounted(){
    console.log(this.gkForm);
  },
  computed: {
    ...mapState({
    }),
  },
  methods: {
    inputChange(e) {
      //强制刷新
      this.$forceUpdate()
    },
    // 关闭标签页（jzy），可以全部关闭，如果要剩下一个不能关闭也可以，这里做的全部关闭,关闭一个自动选择一个
    isOneTrue() {
      let arr = [this.isa, this.isb, this.isc]
      let flag = 0; // 有多少个true
      arr.forEach(item => {
        if (item) {
          flag += 1;
        }
      })
      if (flag > 0) {
        return false;
      } else {
        return true;
      }
    },
    removeTab(targetName) {
        if (targetName === 'a') {
          if (targetName === this.activeName) {
            this.activeName = this.isb ? 'b' : 'c';
          }
          this.isa = false;
        } else if (targetName === 'b') {
          if (targetName === this.activeName) {
            this.activeName = this.isa ? 'a' : 'c';
          }
          this.isb = false;
        } else if (targetName === 'c') {
          if (targetName === this.activeName) {
            this.activeName = this.isb ? 'b' : 'a'
          }
          this.isc = false;
        }
    },


    handleSizeChange(val) {
      this.pageSize = val;
      this.query();
    },

    handleCurrentChange(val) {
      this.pageNumber = val;
      this.query();
    },


    // 查询
    query() {
      this.getListInfo();
    },
    // 新增
    newaddCrowdInfo() {
      this.checkeda01 = false
      this.checkeda02 = false
      this.checked = false
      this.checkedb01 = false
      this.checkedb02 = false
      this.checkedc01 = false
      this.checkedc02 = false
      this.activeName = ''
      this.isa = false
      this.isb = false
      this.isc = false

      this.ruleForm = {}
      this.gkForm = {
        num: 0,
        startBookCode: "",
        startBookName: "",
        relieveBookCode: "",
        relieveBookName: "",
        labDays: [{ labDaysName: "" }],
        antibody: [{ antibodyvalue: "" }],
        controlType: 2
      }
      this.gkFormb = {
        num: 0,
        startBookCode: "",
        startBookName: "",
        relieveBookCode: "",
        relieveBookName: "",
        labDays: [{ labDaysName: "" }],
        antibody: [{ antibodyvalue: "" }],
        controlType: 3
      }
      this.gkFormc = {
        num: 0,
        startBookCode: "",
        startBookName: "",
        relieveBookCode: "",
        relieveBookName: "",
        labDays: [{ labDaysName: "" }],
        antibody: [{ antibodyvalue: "" }],
        controlType: 1
      }
      this.iseddd = false
      this.ruleEditing = true
    },
    // 修改编辑
    async editCrowdInfo(row) {
      this.checkeda01 = false
      this.checkeda02 = false
      this.checked = false
      this.checkedb01 = false
      this.checkedb02 = false
      this.checkedc01 = false
      this.checkedc02 = false
      this.ruleForm = {}
      this.gkForm = {
        num: 0,
        labDays: [{ labDaysName: "" }],
        antibody: [{ antibodyvalue: "" }],
        controlType: 2
      }
      this.gkFormb = {
        num: 0,
        labDays: [{ labDaysName: "" }],
        antibody: [{ antibodyvalue: "" }],
        controlType: 3
      }
      this.gkFormc = {
        num: 0,
        labDays: [{ labDaysName: "" }],
        antibody: [{ antibodyvalue: "" }],
        controlType: 1
      }
      this.iseddd = true
      let params = { id: row.id };
      try {
        let { data } = await ruleCrowdInfo({ params });
        if (data.code == 200) {
          console.log(data.data);
          this.ruleForm = data.data;
          if (this.gkFormb.startBookImplement == 1) {
            this.checkedb01 == true
          } else {
            this.checkedb01 == false
          }
          if (this.gkFormb.relieveBookImplement == 1) {
            this.checkedb02 = true
          } else {
            this.checkedb02 = false
          }

          if (this.gkFormc.startBookImplement == 1) {
            this.checkedc01 = true
          } else {
            this.checkedc01 = false
          }
          if (this.gkFormc.relieveBookImplement == 1) {
            this.checkedc02 = true
          } else {
            this.checkedc02 = false
          }
          this.isa = false
          this.isb = false
          this.isc = false
          console.log(data.data.ruleCrowdControlList);
          for (let i in data.data.ruleCrowdControlList) {
            if (data.data.ruleCrowdControlList[i].controlType == 2) {
              if (data.data.ruleCrowdControlList[i].seq != 0) {
                this.activeName = 'a'
                this.isa = true
              } else {
                this.isa = false
              }

              this.gkForm = data.data.ruleCrowdControlList[i]

              let a = this.gkForm.covidLabDays.split(',')
              this.gkForm.labDays = a.map(item => { return { labDaysName: item } })
              let b = this.gkForm.antibodyLabDays.split(',')
              this.gkForm.antibody = b.map(item => { return { antibodyvalue: item } })

              if (this.gkForm.startBookImplement == 1) {
                this.checkeda01 = true
              } else {
                this.checkeda01 = false
              }
              if (this.gkForm.relieveBookImplement == 1) {
                this.checkeda02 = true
              } else {
                this.checkeda02 = false
              }
              this.$forceUpdate()
            }
            if (data.data.ruleCrowdControlList[i].controlType == 3) {
              if (data.data.ruleCrowdControlList[i].seq != 0) {
                this.activeName = 'b'
                this.isb = true
              } else {
                this.isb = false
              }
              this.gkFormb = data.data.ruleCrowdControlList[i]
              let a = this.gkFormb.covidLabDays.split(',')
              this.gkFormb.labDays = a.map(item => { return { labDaysName: item } })
              let b = this.gkFormb.antibodyLabDays.split(',')
              this.gkFormb.antibody = b.map(item => { return { antibodyvalue: item } })

              if (this.gkFormb.startBookImplement == 1) {
                this.checkedb01 = true
              } else {
                this.checkedb01 = false
              }
              if (this.gkFormb.relieveBookImplement == 1) {
                this.checkedb02 = true
              } else {
                this.checkedb02 = false
              }

            }
            if (data.data.ruleCrowdControlList[i].controlType == 1) {
              if (data.data.ruleCrowdControlList[i].seq != 0) {
                this.activeName = 'c'
                this.isc = true
              } else {
                this.isc = false

              }
              this.gkFormc = data.data.ruleCrowdControlList[i]

              let a = this.gkFormc.covidLabDays.split(',')
              this.gkFormc.labDays = a.map(item => { return { labDaysName: item } })
              let b = this.gkFormc.antibodyLabDays.split(',')
              this.gkFormc.antibody = b.map(item => { return { antibodyvalue: item } })

              if (this.gkFormc.startBookImplement == 1) {
                this.checkedc01 = true
              } else {
                this.checkedc01 = false
              }
              if (this.gkFormc.relieveBookImplement == 1) {
                this.checkedc02 = true
              } else {
                this.checkedc02 = false
              }

            }
          }
        }
      } catch (error) { }

      this.ruleForm = row
      this.ruleEditing = true
    },

    // 查询列表
    async getListInfo() {
      this.loading = true;
      let params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        ruleName:this.ruleName,
        code:this.code,
        status:this.status
      };
      let result = await rulecrowdtist({ params });
      let { code, data, msg } = result.data;
      if (code == 200) {
        this.tableData = data.list;
        this.total = data.totalRow;
      } else {
        this.$message.error(msg)
      }
      this.loading = false;
    },

    // 停用
    async dictionaryFun(row) {
      let params = {
        id: row.id,
        status: row.status == 1 ? 0 : 1
      };
      let { data } = await rulecrowdupdateStatus(params);
      if (data.code == 200) {
        this.getListInfo();
      } else {
        this.$message.error(data.msg);
      }
    },
    handleChange(value) {
    },
    handleClick(tab, event) {
      this.$forceUpdate()
    },
    handleClose(done) {
      this.ruleEditing = false
    },
    // 添加检测点
    addDomain(type) {
      if (type === '核酸') {
        this.gkForm.labDays.push({
          labDaysName: "",
          key: Date.now()
        });
        this.$forceUpdate();

      } else if (type === '抗体') {
        this.gkForm.antibody.push({
          antibodyvalue: "",
          key: Date.now()
        })
        this.$forceUpdate();
      }
    },
    removeDomain(index, type) {
      if (type === '核酸') {
        this.gkForm.labDays.splice(index, 1);
        this.$forceUpdate();
      } else if (type === '抗体') {
        this.gkForm.antibody.splice(index, 1);
        this.$forceUpdate();
      }
    },
    // 添加检测点b
    addDomainb(type) {
      if (type === '核酸') {
        this.gkFormb.labDays.push({
          labDaysName: "",
          key: Date.now()
        });
        this.$forceUpdate()

      } else if (type === '抗体') {
        this.gkFormb.antibody.push({
          antibodyvalue: "",
          key: Date.now(this.gkFormb.antibody)
        })
        this.$forceUpdate()

      }
    },
    removeDomainb(index, type) {
      if (type === '核酸') {
        this.gkFormb.labDays.splice(index, 1);
        this.$forceUpdate();
      } else if (type === '抗体') {
        this.gkFormb.antibody.splice(index, 1);
        this.$forceUpdate();

      }
    },
    // 添加检测点c
    addDomainc(type) {
      if (type === '核酸') {
        this.gkFormc.labDays.push({
          labDaysName: "",
          key: Date.now()
        });
        this.$forceUpdate()

      } else if (type === '抗体') {
        this.gkFormc.antibody.push({
          antibodyvalue: "",
          key: Date.now()
        })
        this.$forceUpdate()

      }
    },
    removeDomainc(index, type) {
      if (type === '核酸') {
        this.gkFormc.labDays.splice(index, 1);
        this.$forceUpdate();

      } else if (type === '抗体') {
        this.gkFormc.antibody.splice(index, 1);
        this.$forceUpdate();

      }
    },
    // 下拉菜单
    handleCommand(command) {
      this.activeName = command
      if (command == 'a') {
        this.isa = true
      } else if (command == 'b') {
        this.isb = true

      } else if (command == 'c') {
        this.isc = true

      }
    },
    // 弹框确认
    async surepp() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.rulecrowdSave();
        }
      });
    },
    // 保存人群分类
    async rulecrowdSave() {
      if (this.checkeda01 == true && !this.gkForm.startBookCode) {
        this.$message.error('请选择开始隔离告知书类型！');
        return
      } else if (this.checkeda02 == true && !this.gkForm.relieveBookCode) {
        this.$message.error('请选择解除隔离告知书类型！');
        return
      }

      if (this.checkedb01 == true && !this.gkFormb.startBookCode) {
        this.$message.error('请选择开始隔离告知书类型！');
        return
      } else if (this.checkedb02 == true && !this.gkFormb.relieveBookCode) {
        this.$message.error('请选择解除隔离告知书类型！');
        return
      }

      if (this.checkedc01 == true && !this.gkFormc.startBookCode) {
        this.$message.error('请选择开始隔离告知书类型！');
        return
      } else if (this.checkedc02 == true && !this.gkFormc.relieveBookCode) {
        this.$message.error('请选择解除隔离告知书类型！');
        return
      }

      if (this.checkeda01 == true) {
        this.gkForm.startBookImplement = 1
      } else {
        this.gkForm.startBookImplement = 0
      }
      if (this.checkeda02 == true) {
        this.gkForm.relieveBookImplement = 1
      } else {
        this.gkForm.relieveBookImplement = 0
      }

      if (this.checkedb01 == true) {
        this.gkFormb.startBookImplement = 1
      } else {
        this.gkFormb.startBookImplement = 0
      }
      if (this.checkedb02 == true) {
        this.gkFormb.relieveBookImplement = 1
      } else {
        this.gkFormb.relieveBookImplement = 0
      }

      if (this.checkedc01 == true) {
        this.gkFormc.startBookImplement = 1
      } else {
        this.gkFormc.startBookImplement = 0
      }
      if (this.checkedc02 == true) {
        this.gkFormc.relieveBookImplement = 1
      } else {
        this.gkFormc.relieveBookImplement = 0
      }


      if (this.gkForm.labDays) {
        let a01 = []
        for (let i in this.gkForm.labDays) {
          if (this.gkForm.labDays[i].labDaysName != undefined) {
            a01.push(this.gkForm.labDays[i].labDaysName)
          }
        }
        a01 = a01.toString(a01)
        this.gkForm.covidLabDays = a01

      }
      if (this.gkForm.antibody) {
        let a02 = []
        for (let i in this.gkForm.antibody) {
          if (this.gkForm.antibody[i].antibodyvalue != undefined) {
            a02.push(this.gkForm.antibody[i].antibodyvalue)
          }
        }
        a02 = a02.toString(a02)
        this.gkForm.antibodyLabDays = a02
      }

      if (this.gkFormb.labDays) {
        let b01 = []
        for (let i in this.gkFormb.labDays) {
          if (this.gkFormb.labDays[i].labDaysName != undefined) {
            b01.push(this.gkFormb.labDays[i].labDaysName)
          }
        }
        b01 = b01.toString(b01)
        this.gkFormb.covidLabDays = b01
        if (this.gkFormb.antibody) {
          let b02 = []
          for (let i in this.gkFormb.antibody) {
            if (this.gkFormb.antibody[i].antibodyvalue != undefined) {
              b02.push(this.gkFormb.antibody[i].antibodyvalue)
            }
          }
          b02 = b02.toString(b02)
          this.gkFormb.antibodyLabDays = b02
        }
        if (this.gkFormc.labDays) {
          let c01 = []
          for (let i in this.gkFormc.labDays) {
            if (this.gkFormc.labDays[i].labDaysName != undefined) {
              c01.push(this.gkFormc.labDays[i].labDaysName)
            }
          }
          c01 = c01.toString(c01)
          this.gkFormc.covidLabDays = c01
        }
        if (this.gkFormc.antibody) {
          let c02 = []
          for (let i in this.gkFormc.antibody) {
            if (this.gkFormc.antibody[i].antibodyvalue != undefined) {
              c02.push(this.gkFormc.antibody[i].antibodyvalue)
            }
          }
          c02 = c02.toString(c02)
          this.gkFormc.antibodyLabDays = c02
        }
        console.log('表头数据', this.ruleForm);  //表头数据
        console.log('居家数据', this.gkForm);  //隔离数据
        console.log('集中数据', this.gkFormb);  //表头数据
        console.log('健康数据', this.gkFormc);  //表头数据
        let ruleCrowdControlList = [];
        if(this.isa){
          ruleCrowdControlList.push(this.gkForm);
        }
        if(this.isb){
          ruleCrowdControlList.push(this.gkFormb);
        }
        if(this.isc){
          ruleCrowdControlList.push(this.gkFormc);
        }
        let params = { ...this.ruleForm, 'ruleCrowdControlList': ruleCrowdControlList }
        console.log('保存数据', params);

        if (this.iseddd) {
          try {
            let { data } = await rulecrowdupdate(params)
            if (data.code == 200) {
              this.ruleEditing = false
              this.getListInfo()
            }
          } catch (error) {

          }

        } else {
          try {
            let { data } = await rulecrowdsave(params)
            if (data.code == 200) {
              this.ruleEditing = false
              this.getListInfo()
            }
          } catch (error) {

          }

        }
      }
    },
    // 人群分类
    async getCrowdTypeList() {
      const { data } = await rqtype();
      if (data.code == "200") {
        this.maincategoryList = data.data;
      }
    },
    // 开始隔离告知书类型
    async getNoticeBookTypeKs() {
      let params = {
        type: 1
      };
      const { data } = await noticeBookType({params});
      if (data.code == "200") {
        this.ksoptions = data.data;
      }
    },
    // 解除隔离告知书类型
    async getNoticeBookTypeJc() {
      let params = {
        type: 2
      };
      const { data } = await noticeBookType({params});
      if (data.code == "200") {
        this.jcoptions = data.data;
      }
    },
    //选择人群分类
    handelCrowdType(item) {
      this.ruleForm.checkForm = item.remark
      this.ruleForm.code = item.dictValue;
      this.ruleForm.name = item.dictLabel;
    },
    //居家隔离:开始隔离告知书类型
    gkFormStartBookCode(item) {
      this.gkForm.startBookName = item.noticeBookName;
    },
    //居家隔离:解除隔离告知书类型
    gkFormRelieveBookCode(item) {
      this.gkForm.relieveBookName = item.noticeBookName;
    },
    //集中隔离:开始隔离告知书类型
    gkFormbStartBookCode(item) {
      this.gkFormb.startBookName = item.noticeBookName;
    },
    //集中隔离:解除隔离告知书类型
    gkFormbRelieveBookCode(item) {
      this.gkFormb.relieveBookName = item.noticeBookName;
    },
    //健康检测:开始隔离告知书类型
    gkFormcStartBookCode(item) {
      this.gkFormb.startBookName = item.noticeBookName;
    },
    //健康检测:解除隔离告知书类型
    gkFormcRelieveBookCode(item) {
      this.gkFormb.relieveBookName = item.noticeBookName;
    },
  }
};
</script>

<style lang='scss' scoped>
.top_btn {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.content_page {
  margin-top: 10px;
  height: 40px;
  display: flex;
  justify-content: flex-end;
}
</style>

<style scoped>
.el-table>>>td {
  padding: 4px 0 !important;
}

.ruleEditing_gk {
  display: flex;
  align-items: center;
}

.isexecute {
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}

.gk_top {
  display: flex;
}

.demo_gkForm {
  margin-top: 10px;
}

.detect {
  display: flex;
}

.detect_hs {
  width: 600px;
}

.detect_kt {
  width: 600px;

}
.mingCheng{
  width: 220px;
}
</style>
